import { cn } from "@src/ui/lib/utils";
import Text from "@src/ui/text";
import { FC } from "react";

const MaxNumberPinnedText: FC = () => {
  return (
    <Text type="P3" className={cn("text-gray-7")}>
      Maximum number of pinned tags reached.
    </Text>
  );
};

export default MaxNumberPinnedText;
