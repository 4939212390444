import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, MediaFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, ContentQrCodeFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagsPageContentTagFragment = { __typename?: 'Tag', id: string, libraryItemCount: number, availableInApp: boolean, pinned?: boolean | null, modulesCount?: number | null, coursesCount?: number | null, checkInsCount?: number | null, resourcesCount?: number | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } };

export type TagsPageContentTagsQueryVariables = Types.Exact<{
  input: Types.TagsInput;
}>;


export type TagsPageContentTagsQuery = { __typename?: 'Query', Tags: Array<{ __typename?: 'Tag', id: string, libraryItemCount: number, availableInApp: boolean, pinned?: boolean | null, modulesCount?: number | null, coursesCount?: number | null, checkInsCount?: number | null, resourcesCount?: number | null, nameTranslations: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } }> };

export type UpdateTagsMutationVariables = Types.Exact<{
  input: Types.UpdateTagsInput;
}>;


export type UpdateTagsMutation = { __typename?: 'Mutation', updateTags: { __typename?: 'TagMutationResult', success: boolean } };

export type DeleteTagsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DeleteTagsMutation = { __typename?: 'Mutation', deleteTags: { __typename?: 'TagMutationResult', success: boolean } };

export const TagsPageContentTagFragmentDoc = gql`
    fragment TagsPageContentTag on Tag {
  id
  nameTranslations {
    ...TranslationSet
  }
  libraryItemCount
  availableInApp
  pinned
  modulesCount
  coursesCount
  checkInsCount
  resourcesCount
}
    ${TranslationSetFragmentDoc}`;
export const TagsPageContentTagsDocument = gql`
    query TagsPageContentTags($input: TagsInput!) {
  Tags(input: $input) {
    ...TagsPageContentTag
  }
}
    ${TagsPageContentTagFragmentDoc}`;

/**
 * __useTagsPageContentTagsQuery__
 *
 * To run a query within a React component, call `useTagsPageContentTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsPageContentTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsPageContentTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagsPageContentTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsPageContentTagsQuery, TagsPageContentTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsPageContentTagsQuery, TagsPageContentTagsQueryVariables>(TagsPageContentTagsDocument, options);
      }
export function useTagsPageContentTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsPageContentTagsQuery, TagsPageContentTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsPageContentTagsQuery, TagsPageContentTagsQueryVariables>(TagsPageContentTagsDocument, options);
        }
export type TagsPageContentTagsQueryHookResult = ReturnType<typeof useTagsPageContentTagsQuery>;
export type TagsPageContentTagsLazyQueryHookResult = ReturnType<typeof useTagsPageContentTagsLazyQuery>;
export type TagsPageContentTagsQueryResult = Apollo.QueryResult<TagsPageContentTagsQuery, TagsPageContentTagsQueryVariables>;
export const UpdateTagsDocument = gql`
    mutation UpdateTags($input: UpdateTagsInput!) {
  updateTags(input: $input) {
    success
  }
}
    `;
export type UpdateTagsMutationFn = Apollo.MutationFunction<UpdateTagsMutation, UpdateTagsMutationVariables>;

/**
 * __useUpdateTagsMutation__
 *
 * To run a mutation, you first call `useUpdateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagsMutation, { data, loading, error }] = useUpdateTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagsMutation, UpdateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagsMutation, UpdateTagsMutationVariables>(UpdateTagsDocument, options);
      }
export type UpdateTagsMutationHookResult = ReturnType<typeof useUpdateTagsMutation>;
export type UpdateTagsMutationResult = Apollo.MutationResult<UpdateTagsMutation>;
export type UpdateTagsMutationOptions = Apollo.BaseMutationOptions<UpdateTagsMutation, UpdateTagsMutationVariables>;
export const DeleteTagsDocument = gql`
    mutation DeleteTags($ids: [String!]!) {
  deleteTags(ids: $ids) {
    success
  }
}
    `;
export type DeleteTagsMutationFn = Apollo.MutationFunction<DeleteTagsMutation, DeleteTagsMutationVariables>;

/**
 * __useDeleteTagsMutation__
 *
 * To run a mutation, you first call `useDeleteTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagsMutation, { data, loading, error }] = useDeleteTagsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagsMutation, DeleteTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagsMutation, DeleteTagsMutationVariables>(DeleteTagsDocument, options);
      }
export type DeleteTagsMutationHookResult = ReturnType<typeof useDeleteTagsMutation>;
export type DeleteTagsMutationResult = Apollo.MutationResult<DeleteTagsMutation>;
export type DeleteTagsMutationOptions = Apollo.BaseMutationOptions<DeleteTagsMutation, DeleteTagsMutationVariables>;