import { useTagsPageContentTagsQuery } from "../TagsPageContent.generated";

export const useMaxNumberTagsPinned = (): boolean => {
  const { data } = useTagsPageContentTagsQuery({
    variables: {
      input: {},
    },
  });

  const pinnedTagsCount = data?.Tags.filter((tag) => tag.pinned).length || 0;
  return pinnedTagsCount >= 8;
};
