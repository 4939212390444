import { FC, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@src/ui/dialog";
import TranslationTextField from "@src/deprecatedDesignSystem/components/TranslationTextField";
import { Checkbox } from "@src/ui/checkbox";
import { Button } from "@src/ui/button";
import useTagContext from "@src/contexts/TagContext/hooks/useTagContext";
import PinTackOutlineIcon from "@src/ui/icons/18px/pin-tack-outline";
import Text from "@src/ui/text";
import MobileOutlineIcon from "@src/ui/icons/18px/mobile-outline";
import { useMaxNumberTagsPinned } from "./hooks/useMaxNumberTagsPinned";
import MaxNumberPinnedText from "./MaxNumberPinnedText";
import { cn } from "@src/ui/lib/utils";
import { TagsPageContentTagFragment } from "@src/components/tags/TagsPageContent.generated";

type Props = {
  tag: TagsPageContentTagFragment;
  onClose: () => void;
};
export const EditTagModalV2: FC<Props> = ({ tag, onClose }) => {
  const tagContext = useTagContext();
  const [pinned, setPinned] = useState(tag.pinned || false);
  const [availableInApp, setAvailableInApp] = useState(tag.availableInApp);
  const [nameTranslations, setNameTranslations] = useState(
    tag.nameTranslations,
  );

  const maxAmountPinned = useMaxNumberTagsPinned();

  const pinningDisabled = useMemo(
    () => maxAmountPinned && !tag.pinned,
    [maxAmountPinned, tag.pinned],
  );

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Tag</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-3 py-1">
          <Text type="P2" fontWeight="Medium">
            Tag Name
          </Text>
          {tag && (
            <TranslationTextField
              value={nameTranslations}
              onChange={(translationSet) => {
                setNameTranslations(translationSet);
              }}
            />
          )}
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1.5">
            <PinTackOutlineIcon className="text-gray-7" />
            <Checkbox
              id="pinned"
              checked={pinned}
              onCheckedChange={(checked) => setPinned(!!checked)}
              disabled={pinningDisabled}
            />
          </div>
          <Text
            type="P2"
            fontWeight="Medium"
            className={cn(pinningDisabled ? "text-gray-7" : "text-foreground")}
          >
            Pinned
          </Text>
          {pinningDisabled && <MaxNumberPinnedText />}
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1.5">
            <MobileOutlineIcon className="text-gray-7" />
            <Checkbox
              id="availableInApp"
              checked={availableInApp}
              onCheckedChange={(checked) => setAvailableInApp(!!checked)}
            />
          </div>
          <Text type="P2" fontWeight="Medium">
            Available In App
          </Text>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (tag) {
                tagContext.editTag(tag.id, {
                  nameTranslations,
                  availableInApp,
                  pinned: { value: pinned },
                });
                onClose();
              }
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditTagModalV2;
