import React, { FC, useMemo, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@ui/command";
import { gql } from "graphql-request";
import { orderBy } from "lodash";
import { cn } from "@src/ui/lib/utils";
import TagPill from "@src/components/ui/TagPill";
import Text from "@src/ui/text";
import { LibraryItemType } from "@src/types.generated";
import {
  TagDropdownContentTagFragment,
  useTagDropdownContentTagsQuery,
} from "./TagSelectDropdown.generated";

import { Button } from "@src/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import PenOutlineIcon from "@src/ui/icons/18px/pen-outline";
import EditTagModal from "./EditTagModal";
import DeleteTagModal from "./DeleteTagModal";
import CreateTagModal from "./CreateTagModal";
import TrashOutlineIcon from "@src/ui/icons/18px/trash-outline";
import CheckmarkIcon from "@src/ui/icons/18px/checkmark";
import DotsIcon from "@src/ui/icons/18px/dots";
import useImprovedLeftNav from "../contentLibrary/hooks/useImprovedLeftNav";
import EditTagModalV2 from "./EditTagModalV2";
import CreateTagModalV2 from "./CreateTagModalV2";

type Props = {
  selectedTagIds: string[];
  onSelectTag: (id: string) => void;
  onUnselectTag: (id: string) => void;
  itemTypes?: LibraryItemType[];
  drillDown?: boolean;
};

const TagSelectDropdown: FC<Props> = ({
  selectedTagIds,
  onSelectTag,
  onUnselectTag,
  itemTypes,
  drillDown,
}) => {
  const { data, previousData } = useTagDropdownContentTagsQuery({
    variables: {
      input: {
        libraryItemFilters: drillDown
          ? {
              tagIds:
                selectedTagIds?.length > 0
                  ? { value: selectedTagIds }
                  : undefined,
              itemTypes: itemTypes ? { value: itemTypes } : undefined,
            }
          : undefined,
      },
    },
  });

  const improvedLeftNavEnabled = useImprovedLeftNav();

  const tags = useMemo(() => {
    const _tags = data?.Tags || previousData?.Tags || [];
    return orderBy(_tags, (t) => t.nameTranslations.en.toLowerCase().trim(), [
      "asc",
    ]);
  }, [data, previousData]);
  const [creatingTag, setCreatingTag] = useState<boolean>(false);
  const [tagToDelete, setTagToDelete] =
    useState<TagDropdownContentTagFragment | null>(null);
  const [tagToEdit, setTagToEdit] =
    useState<TagDropdownContentTagFragment | null>(null);

  return (
    <>
      <Command className="max-h-80 overflow-hidden">
        <CommandInput placeholder="Search tags..." />
        <CommandEmpty>No tags found.</CommandEmpty>
        <CommandGroup className="max-h-60 overflow-y-auto">
          {tags.map((tag) => (
            <CommandItem
              key={tag.id}
              onSelect={() => {
                selectedTagIds.includes(tag.id)
                  ? onUnselectTag(tag.id)
                  : onSelectTag(tag.id);
              }}
              className="group"
            >
              <CheckmarkIcon
                className={cn(
                  "mr-2 h-4 w-4",
                  selectedTagIds.includes(tag.id) ? "opacity-100" : "opacity-0",
                )}
              />
              <TagPill tagId={tag.id} truncateAt={30} />
              <Text type={"P3"} className="ml-auto">
                {tag.libraryItemCount}
              </Text>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <DotsIcon className="pl-1 text-muted-foreground opacity-0 transition-opacity group-hover:opacity-100" />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setTagToEdit(tag);
                    }}
                  >
                    <PenOutlineIcon className="mr-2 size-4" />
                    Edit
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setTagToDelete(tag);
                    }}
                  >
                    <TrashOutlineIcon className="mr-2 size-4" />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </CommandItem>
          ))}
        </CommandGroup>
        <div className="border-t p-2">
          <Button
            variant="ghost"
            className="w-full text-primary"
            onClick={() => setCreatingTag(true)}
          >
            Create Tag
          </Button>
        </div>
      </Command>
      {creatingTag &&
        (improvedLeftNavEnabled ? (
          <CreateTagModalV2
            onClose={() => setCreatingTag(false)}
            onComplete={(tag) => {
              onSelectTag(tag.id);
            }}
          />
        ) : (
          <CreateTagModal
            onClose={() => setCreatingTag(false)}
            onComplete={(tag) => {
              onSelectTag(tag.id);
            }}
          />
        ))}
      {tagToEdit &&
        (improvedLeftNavEnabled ? (
          <EditTagModalV2 tag={tagToEdit} onClose={() => setTagToEdit(null)} />
        ) : (
          <EditTagModal tag={tagToEdit} onClose={() => setTagToEdit(null)} />
        ))}
      {tagToDelete && (
        <DeleteTagModal
          tag={tagToDelete}
          onClose={() => setTagToDelete(null)}
          onComplete={() => {
            onUnselectTag(tagToDelete.id);
          }}
        />
      )}
    </>
  );
};

export default TagSelectDropdown;

gql`
  fragment TagDropdownContentTag on Tag {
    id
    nameTranslations {
      ...TranslationSet
    }
    libraryItemCount
    availableInApp
  }
  query TagDropdownContentTags($input: TagsInput!) {
    Tags(input: $input) {
      ...TagDropdownContentTag
    }
  }
`;
